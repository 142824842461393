<template>
    <div class="orders-history-order-product">
        <!-- Thumbnail -->
        <div class="orders-history-order-product-thumbnail">
            <img v-if="thumbnail" class="orders-history-order-product-thumbnail-img" :src="thumbnail" alt=""/>
        </div>

        <div class="flex-grow-1">
            <div class="d-flex">
                <div class="orders-history-order-product-name">
                    <div class="flex-grow-1 text-break">
                        {{title}}
                        <div v-if="quantity && 1 < quantity">
                            <small>{{t('quantity')}}: {{quantity}}</small>
                        </div>
                    </div>
                    <div v-html="createdOn"></div>
                </div>
                <div class="flex-grow-0 pl-3">
                    <button type="button" class="btn-switcher" @click="display.details=!display.details">
                        <span :class="{'icon-plus':!display.details,'icon-minus':display.details}"></span>
                    </button>
                </div>
            </div>

            <div v-if="display.details">
                <div class="info">
                    <!-- Status -->
                    <div>
                        {{displayStatus}}
                        <span class="pl-2" v-html="statusDate"></span>
                    </div>
                    <!-- Tracking -->
                    <div v-if="product.tracking" class="tracking">
                        {{t('tracking')}}:
                        <span v-if="product.trackingUrl">
                            <a :href="product.trackingUrl" target="_blank" rel="noopener">{{product.tracking}}</a>
                        </span>
                        <span v-else>{{product.tracking}}</span>
                    </div>
                    <!-- Not approved reason-->
                    <div v-if="approvalReason" class="status">
                        {{t('reason')}}: {{approvalReason}}
                    </div>
                    <!-- Status -->
                    <div v-if="estimatedDelivery" class="status">
                        {{estimatedDelivery}}
                    </div>
                    <!-- Requested by -->
                    <div v-if="orderedBy" class="status">
                        {{t('orderedBy')}}: {{orderedBy}}
                    </div>
                </div>
                <!-- Cancel -->
                <div v-if="ctaAllowed.cancel" class="control">
                    <popper :content="t('control.cancel.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm"
                                :disabled="processing.cancel"
                                @click="cancel(product)">
                            <span v-if="processing.cancel" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.cancel.cta')"></span>
                        </button>
                    </popper>
                </div>
                <!-- Request cancellation -->
                <div v-if="ctaAllowed.cancelRequest" class="control">
                    <popper :content="t('control.cancelRequest.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.cancelRequest"
                                @click="display.cancelRequest=!display.cancelRequest">
                            <span v-if="processing.cancelRequest" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.cancelRequest.cta')"></span>
                        </button>
                    </popper>
                </div>
                <!-- Mark as received -->
                <div v-if="ctaAllowed.received" class="control">
                    <popper :content="t('control.received.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.received"
                                @click="received">
                            <span v-if="processing.received" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.received.cta')"></span>
                        </button>
                    </popper>
                </div>
                <!-- Mark as not received -->
                <div v-if="ctaAllowed.notReceived" class="control">
                    <popper :content="t('control.notReceived.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.notRecieved"
                                @click="notReceived">
                            <span v-if="processing.notRecieved" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.notReceived.cta')"></span>
                        </button>
                    </popper>
                    <a href="#" ref="missed" class="d-none"
                       :data-widget-trigger="widget_appendSelectionProducts(widget_optionsJson_orderQuestions_whereOrder, [product])"></a>
                </div>
                <!-- Mark as not damaged -->
                <div v-if="ctaAllowed.damaged" class="control">
                    <popper :content="t('control.damaged.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.damaged"
                                @click="damaged">
                            <span v-if="processing.damaged" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.damaged.cta')"></span>
                        </button>
                    </popper>
                    <a href="#" ref="damaged" class="d-none"
                       :data-widget-trigger="widget_appendSelectionProduct(widget_optionsJson_productIssues_productDamaged, product)"></a>
                </div>
                <!-- Hide -->
                <div v-if="ctaAllowed.hide" class="control">
                    <popper :content="t('control.hide.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.hide"
                                @click="hide">
                            <span v-if="processing.hide" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.hide.cta')"></span>
                        </button>
                    </popper>
                </div>
                <!-- Request to cancel -->
                <form class="request-cancel" v-if="display.cancelRequest"
                      @submit.prevent="submitRequestCancellation(product._id)">

                    <form-textarea v-model="model.cancel.reason"
                                   :label="t('model.cancel.reason.label')"
                                   :validator="v$.model.cancel.reason"
                                   :placeholder="t('model.cancel.reason.placeholder')"
                                   :disabled="processing.cancelRequest"/>

                    <div class="d-flex">
                        <button type="submit" class="btn btn-sm btn-primary px-1 w-50 mr-1"
                                :disabled="processing.cancelRequest">
                            <small>
                                <spinner v-if="processing.cancelRequest"></spinner>
                                {{processing.cancelRequest ? t('buttons.cancel.submit.processing') : t('buttons.cancel.submit.cta')}}
                            </small>
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-primary px-1 w-50 ml-1"
                                :disabled="processing.cancelRequest"
                                @click="display.cancelRequest=false">
                            <small>
                                {{t('buttons.cancel.cancel.cta')}}
                            </small>
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import get from "lodash/get.js";
    import Popper from "vue3-popper";
    import useVuelidate from "@vuelidate/core";

    import mixin_widget from "@mixins_widget";
    import mixin_products from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";
    import {ORDER_STATUS} from "@/constants";

    import FormTextarea from "@/components/form/FormTextarea";
    import Spinner from "@/components/Spinner";
    import ModalComponentsOrdersActionUndo from "@/modal/components/orders/ModalComponentsOrdersActionUndo";

    const TIME_LIMIT = process.env.VUE_APP_COMPONENT_ORDERHISTORY_ORDER_PRODUCT_TIME_LIMIT * 1000;

    export default {
        name: "OrdersHistoryOrderProduct",
        mixins: [mixin_widget, mixin_products],
        components: {
            Spinner,
            FormTextarea,
            Popper
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            expand: Boolean
        },
        data() {

            return {
                t_path: "components.orders.history.order.product",

                display: {
                    cancel: false,
                    details: !!this.expand
                },

                processing: {
                    cancel: false,
                    cancelRequest: false,
                    received: false,
                    notRecieved: false,
                    damaged: false,
                    hide: false
                },
                model: {
                    cancel: {
                        reason: null
                    }
                }
            }
        },
        computed: {
            thumbnail() {

                return this.product.pictures[0].medium;
            },
            title() {

                return this.products_translateProductName(this.product);
            },
            createdOn() {

                return this
                    .d(this.product.statusHistory[this.product.statusHistory.length - 1].createdOn, "date")
                    .toString()
                    .replace(/\s/ig, "&nbsp;");
            },
            quantity() {

                return this.product.quantity;
            },
            estimatedDelivery() {

                return this.product.estimated_ship_date && this.product.estimated_ship_date.message
                    ? getTranslationValue(this.product.estimated_ship_date, ["message"], this.$i18n)
                    : null
            },
            isImpersonated() {

                return null !== this.$store.getters["user/impersonatorToken"];
            },
            orderedBy() {

                const firstStatus = this.product.statusHistory[this.product.statusHistory.length - 1];

                return false === this.isImpersonated
                && firstStatus
                && firstStatus.requested_by
                && firstStatus.requested_by.id !== this.$store.getters["user/id"]
                && firstStatus.requested_by.name
                    ? firstStatus.requested_by.name
                    : null;
            },
            ctaAllowed() {

                const timeBorder = new Date(new Date().getTime() - TIME_LIMIT).getTime();
                const isOverTimeLimit = this.product.lastStatus.createdOn < timeBorder;

                return {
                    cancel: -1 !== [
                        ORDER_STATUS.CREATED,
                        ORDER_STATUS.CONFIRMED,
                        ORDER_STATUS.SUBMITTED,
                        ORDER_STATUS.PENDING_APPROVAL,
                        ORDER_STATUS.APPROVED
                    ].indexOf(this.product.status),

                    cancelRequest: -1 !== [
                        ORDER_STATUS.SENT,
                        ORDER_STATUS.PREPARING,
                        ORDER_STATUS.PROCESSING,
                        ORDER_STATUS.PROCESSED
                    ].indexOf(this.product.status) && false === this.isImpersonated,
                    received: -1 !== [
                        ORDER_STATUS.ACKNOWLEDGED,
                        ORDER_STATUS.DELIVERY_EMAIL,
                        ORDER_STATUS.SHIPPED,
                        ORDER_STATUS.PROCESSING,
                        ORDER_STATUS.PROCESSED,
                        ORDER_STATUS.DELIVERY_NOT_RECEIVED,
                        ORDER_STATUS.SHIPMENT_EXCEPTION,
                        ORDER_STATUS.DELIVERY_NOT_CONFIRMED,
                        ORDER_STATUS.DELIVERY_EXCEPTION
                    ].indexOf(this.product.status),
                    notReceived: false === this.isImpersonated
                        && (
                            -1 !== [ORDER_STATUS.DELIVERY_EXCEPTION, ORDER_STATUS.SHIPMENT_EXCEPTION].indexOf(this.product.status)
                            || (
                                ORDER_STATUS.DELIVERY_NOT_CONFIRMED === this.product.status
                                && false === isOverTimeLimit
                            )
                        ),
                    damaged: -1 !== [
                        ORDER_STATUS.DELIVERY_NOT_CONFIRMED,
                        ORDER_STATUS.DELIVERED,
                        ORDER_STATUS.DELIVERY_RECEIVED
                    ].indexOf(this.product.status) && false === this.isImpersonated,
                    hide: -1 !== [
                        ORDER_STATUS.CANCELED,
                        ORDER_STATUS.RETURNED,
                        ORDER_STATUS.NOT_APPROVED
                    ].indexOf(this.product.status) && false === this.isImpersonated
                }
            },
            displayStatus() {

                return this.$t(`orders.status.${this.product.status}`);
            },
            statusDate() {

                return this
                    .d(this.product.statusHistory[0].createdOn, "date")
                    .toString()
                    .replace(/\s/ig, "&nbsp;");
            },
            genericName() {

                return this.products_translateProductName(this.product);
            },
            note() {

                return this.products_translateProductNote(this.product);
            },
            approvalReason() {

                return -1 === [ORDER_STATUS.CANCELED].indexOf(this.product.status)
                    ? get(this.product, "approval.approval_reason", null)
                    : null;
            }
        },
        validations() {

            return {
                model: {
                    cancel: {
                        reason: {}
                    }
                }
            }
        },
        methods: {
            cancel(product) {

                this.processing.cancel = true;

                this.$store
                    .dispatch("user/orders/cancel", {id: product._id, sendEmail: true})
                    .then(() => {

                        this.$modal
                            .show({
                                component: ModalComponentsOrdersActionUndo,
                                props: {
                                    message: this.t("control.cancel.message"),
                                    orderProductId: this.product._id
                                }
                            })
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.cancel = false);
            },
            submitRequestCancellation(productId) {

                this.processing.cancelRequest = true;

                this.$platform
                    .post(
                        "/api/v2/widget/stylerequest",
                        {
                            category: "Order Questions",
                            details: this.model.cancel.reason,
                            products: [productId],
                            issue: "Request to Cancel"
                        },
                        {
                            params: {
                                app_id: process.env.VUE_APP_ID,
                                app_secret: process.env.VUE_APP_SECRET
                            }
                        }
                    )
                    .then(() => {

                        this.display.cancelRequest = false;
                        this.model.cancel.reason = null;
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.cancelRequest = false)
            },
            received() {

                this.processing.received = true;

                return this.$store
                    .dispatch("user/orders/received", this.product._id)
                    .then(() => {

                        this.$modal
                            .show({
                                component: ModalComponentsOrdersActionUndo,
                                props: {
                                    message: this.t("control.received.message"),
                                    orderProductId: this.product._id
                                }
                            });
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.received = false);
            },
            notReceived() {

                this.processing.notRecieved = true;

                this.$store
                    .dispatch("widget/setOptions", {orderProduct: this.product})
                    .then(() => {
                        this.widget_refresh_promise();
                        setTimeout(() => {

                            this.$refs.missed.dispatchEvent(new Event("click"));

                            return this.$store
                                .dispatch("user/orders/notReceived", this.product._id)
                                .catch(error => this.base_error(error))
                                .finally(() => this.processing.notRecieved = false);

                        }, 100);
                    });
            },
            damaged() {

                this.$store
                    .dispatch("widget/setOptions", {orderProduct: this.product})
                    .then(() => {
                        this.widget_refresh_promise();
                        setTimeout(() => this.$refs.damaged.dispatchEvent(new Event("click")), 100);
                    })
            },
            hide() {

                this.processing.hide = true;

                this.$store
                    .dispatch("user/orders/hide", this.product._id)
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.hide = false);
            }
        }
    }
</script>