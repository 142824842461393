export default {
    title: "Verificação de login necessária",
    header: "@:views.auth.confirm.title",
    message: "Para continuar, verifique o e-mail da sua empresa para obter um link de verificação de e-mail. Clique no link do e-mail para prosseguir.",
    messageBeforeCta: "Se você não vir o e-mail, clique em",
    messageAfterCta: "para reenviar.",
    secondMessage: "Se você ainda não vir o e-mail, verifique sua pasta de spam ou entre em contato conosco pelo <a href='mailto:{email}?subject={subject}'>e-mail {email}</a> .",
    subject: "Equipamento - TFA",
    buttons: {
        cta: "aqui",
        processing: "aqui"
    },
    headerAlreadyConfirmed: "Verificação de login",
    messageAlreadyConfirmed: "Você já confirmou seu e-mail."
}