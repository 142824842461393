export default {
    country: {
        label: "País de Residência",
        errors: {
            required: "É necessária uma seleção"
        }
    },
    entity: {
        label: "Entidade",
        errors: {
            required: "É necessária uma seleção"
        }
    },
    location: {
        label: "Localização do escritório",
        errors: {
            required: "É necessária uma seleção"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Escritório Dedicado",
                description: "Home office com local permanente dedicado como escritório"
            },
            "Semi-Dedicated": {
                title: "Semi-Dedicado",
                description: "Localização temporária para acomodar as necessidades de um ambiente de trabalho doméstico"
            },
            "Floating": {
                title: "Flutuando",
                description: "Não há espaço dedicado para home office, mas é compartilhado com outros locais, como sala de jantar ou sala de estar"
            }
        },
        errors: {
            required: "É necessária uma seleção"
        }
    },
    priority: {
        label: "Prioridade máxima",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Estação de acoplamento"
            },
            "Keyboard/Mouse": {
                title: "Teclado mouse"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Fone de ouvido"
            },
            "Office Chair": {
                title: "Cadeira de escritório"
            },
            "Desk": {
                title: "Mesa"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Fonte de alimentação ininterrupta (UPS)"
            },
            "Something Else": {
                title: "Algo mais"
            },
            "Nothing Needed": {
                title: "Nada é necessário"
            }
        },
        errors: {
            requiredUnless: "É necessária uma seleção",
            requiredIf: "É necessária uma seleção",
            required: "É necessária uma seleção"
        }
    },
    priorityDetails: {
        placeholder: "Forneça detalhes"
    },
    secondaryPriority: {
        label: "Segunda maior prioridade",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Segundo monitor"
            },
            "Docking Station": {
                title: "Estação de acoplamento"
            },
            "Keyboard/Mouse": {
                title: "Teclado mouse"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Fone de ouvido"
            },
            "Office Chair": {
                title: "Cadeira de escritório"
            },
            "Desk": {
                title: "Mesa"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Fonte de alimentação ininterrupta (UPS)"
            },
            "Something Else": {
                title: "Algo mais"
            },
            "Nothing Needed": {
                title: "Nada é necessário"
            }
        },
        errors: {
            requiredUnless: "É necessária uma seleção",
            requiredIf: "É necessária uma seleção",
            required: "É necessária uma seleção"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Forneça detalhes"
    },
    improveWorkspace: {
        label: "Como você melhoraria seu espaço de trabalho?",
        placeholder: "Opcional",
    },
    improveRemoteWorking: {
        label: "Fora do equipamento, o que o ajudaria a ser mais eficaz no trabalho remoto?",
        placeholder: "Opcional",
    },
    receivedEquipmentHeadset: {
        label: "Você recebeu um fone de ouvido ou viva-voz sem fio da Empresa nos últimos 24 meses?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesHeadset: {
        label: "Você tem problemas com isso?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Descreva seus problemas"
    },
    isOfficeWorker: {
        label: "Você trabalha no escritório em tempo integral?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Você recebeu teclado e mouse da Empresa nos últimos 12 meses?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Você tem problemas com isso?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Descreva seus problemas"
    },
    needWebcam: {
        label: "Você precisa de uma webcam externa para trabalhar (não aquela embutida no seu laptop?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    needWebcamExternal: {
        label: "Você precisa de uma webcam USB externa (não aquela embutida no seu laptop)?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    needMonitor: {
        label: "Você precisa de um monitor para trabalhar?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    receivedMonitor: {
        label: "Você recebeu algum monitor da Empresa nos últimos 4 anos?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesMonitor: {
        label: "Você tem problemas com isso?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Descreva seus problemas"
    },
    needUsbhub: {
        label: "Você precisa de um hub USB externo para trabalhar?",
        values: {
            Yes: {
                name: "Sim"
            },
            No: {
                name: "Não"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Descreva seus problemas"
    }
}