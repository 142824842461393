export default {
    title: "Itens não entregues",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Obrigado",
        message: "Obrigado pela sua resposta."
    },
    message: "Você indicou que não recebeu seu item, apesar do correio marcá-lo como entregue.",
    cta: "Siga estas etapas para nos ajudar a solucionar o problema:",
    items: {
        1: {
            message: "Clique no link de rastreamento:"
        },
        2: {
            message: "Revise o status da entrega. Verificar se há:",
            items: {
                1: "Quem assinou o pacote",
                2: "Se foi devolvido ao remetente",
                3: "Se o pacote estiver aguardando coleta"
            }
        },
        3: {
            message: "Verifique se há um aviso de entrega indicando onde o item pode ter sido deixado."
        },
        4: {
            message: "Verifique com vizinhos, parentes e amigos quem pode ter levado o pacote."
        },
        5: {
            message: "Verifique os locais comuns onde os entregadores deixam itens na sua ausência."
        }
    },
    question: "Isso ajudou a resolver sua dúvida sobre entrega? Você já tem o item ou sabe quem o tem?",
    buttons: {
        yes: "Sim",
        no: "Não",
        saving: "Salvando"
    }
}