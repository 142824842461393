export default {
    status: {
        acknowledged: "Encomendado",
        created: "Criada",
        sent: "Encomendado",
        confirmed: "Encomendado",
        not_confirmed: "Não encomendado",
        delivery_email: "Enviado",
        shipped: "Enviado",
        delayed: "Atrasado",
        preparing: "Preparando",
        processing: "Em processamento",
        processed: "Processado",
        submitted: "Encomendado",
        delivery_not_received: "Enviado",
        shipment_exception: "Enviado",
        pending_approval: "Pendente",
        approved: "Aprovado",
        not_approved: "Não aprovado",
        canceled: "Cancelado",
        returned: "Devolvida",
        lost_shipment: "Remessa Perdida",
        delivery_not_confirmed: "Entregue",
        delivered: "Entregue",
        delivery_received: "Entregue",
        refurbished: "Devolução concluída",
        returned_to_office: "Retornou ao escritório",
        return_to_employer: "Devolvido ao empregador",
        return_pickup_scheduled: "Retirada agendada",
        return_pickup_completed: "Retirada concluída",
        return_pickup_excluded: "Retirada excluída",
        return_pickup_submitted: "Retirada enviada",
        return_pickup_finalized: "Retirada finalizada",
        disposed: "Eliminado",
        damaged: "Danificado"
    }
}