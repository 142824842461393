export default {
    type: {
        "ac_router": "Você atingiu o limite de pedidos de roteadores AC",
        "chair": "Você atingiu o limite de pedidos de cadeiras",
        "default": "Você atingiu o limite de pedidos",
        "default_multiple": "Você atingiu o limite de pedidos de produtos similares",
        "desk": "Você atingiu o limite de pedidos de mesas",
        "desktop": "Você atingiu o limite de pedidos do desktop",
        "desktop_riser": "Você atingiu o limite de pedido de risers de desktop",
        "detector": "Você atingiu o limite de pedidos de detectores de vapor e ruído",
        "displayport_cable": "Você atingiu o limite de pedidos de cabos displayport",
        "docking station": "Você atingiu o limite de pedidos de estações de acoplamento",
        "dual_usb_adapter": "Você atingiu o limite de pedidos de adaptadores USB de banda dupla",
        "ear cushion": "Você atingiu o limite de pedidos de almofadas auriculares",
        "footrest": "Você atingiu o limite de pedidos de apoios para os pés",
        "hdmi_cable": "Você atingiu o limite de pedidos de cabos HDMI",
        "hdmi_displayport_cable": "Você atingiu o limite de pedidos de cabos HDMI para DisplayPort",
        "hdmi_vga_cable": "Você atingiu o limite de pedidos de cabos HDMI para VGA",
        "headset": "Você atingiu o limite de pedidos de dispositivos de áudio",
        "keyboard": "Você atingiu o limite de pedido de teclados",
        "kvm switch": "Você atingiu o limite de pedidos de switches kvm",
        "kvm_switch": "Você atingiu o limite de pedidos de switches kvm",
        "lamp": "Você atingiu o limite de pedidos de lâmpadas",
        "laptop stand": "Você atingiu o limite de pedidos de suportes para laptop",
        "laptop": "Você atingiu o limite de pedidos de laptops",
        "mesh_router": "Você atingiu o limite de pedidos de roteadores mesh",
        "monitor": "Você atingiu o limite de pedidos de monitores",
        "mouse": "Você atingiu o limite de pedidos de mouses",
        "patch_cord": "Você atingiu o limite de pedidos de patch cords Cat6",
        "power_center": "Você atingiu o limite de pedidos de Power Centers",
        "power_strip": "Você atingiu o limite de pedidos de filtros de linha",
        "smart_plug": "Você atingiu o limite de pedidos de smart plugs mini",
        "smart_power_strip": "Você atingiu o limite de pedidos de filtros de linha inteligentes",
        "thunderbolt_cable": "Você atingiu o limite de pedidos de USB-C para Thunderbolt",
        "travel_router": "Você atingiu o limite de pedidos de roteadores de viagem",
        "usb hub": "Você atingiu o limite de pedidos de hubs USB",
        "usb_adapter": "Você atingiu o limite de pedido de adaptadores USB",
        "usb_hub": "Você atingiu o limite de pedidos de hubs USB",
        "webcam": "Você atingiu o limite de pedidos de webcams"
    }
}