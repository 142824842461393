export default {
    disclaimer: {
        label_default: "Reconheço e concordo que os itens que estou encomendando/recebendo são propriedade da Empresa para serem usados em atividades relacionadas ao trabalho da Empresa e, como tal, a Empresa reserva-se o direito de solicitar a devolução da propriedade após minha saída da Empresa (ou antes, se exigido pela Empresa).",
        label_type_1: "Confirmo que a autoavaliação para trabalho de casa foi concluída com precisão e de boa fé, e qualquer equipamento ou mobiliário fornecido em um local da Empresa ou encomendado através da Gear será usado por mim para o propósito do meu trabalho com a Empresa. Reconheço e concordo que os itens que estou encomendando/recebendo são propriedade da Empresa para serem usados em atividades relacionadas ao trabalho da Empresa e, como tal, a Empresa reserva-se o direito de solicitar a devolução da propriedade após minha saída da Empresa (ou antes, se exigido pela Empresa).",
        errors: {
            required: "Por favor, aceite os termos de uso"
        }
    },
    cost_center: {
        label: "Código do Centro de Custo",
        placeholder: "Código do Centro de Custo",
        cost_center_not_found: "Centros de custos não presentes podem levar até 24 horas para aparecer. Entre em contato com o Global Service Desk se não encontrar o centro de custo apropriado.",
        errors: {
            regex: "Código de centro de custo inválido"
        }
    },
    company_code: {
        label: "Código da companhia"
    },
    cost_center_name: {
        label: "Nome do centro de custo"
    },
    order_note: {
        label: "Nota de pedido",
        placeholder: "Nota opcional ao gerente para aprovação",
    }
}