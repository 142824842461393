export default {
    title: "Central de Ajuda",
    header: "Central de Ajuda",
    model: {
        email: {
            label: "E-mail do usuário",
            placeholder: "Por favor preencha o e-mail do usuário"
        }
    },
    cta: "Enviar",
    processing: "Carregando"
}