export default {

    title: "Carregando...",

    responses: {
        "200_tfalogin": {
            title: "Verificação de login",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "O login foi verificado."
        },
        "404_tfalogin": {
            title: "Verificação de login",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Token válido não encontrado. Seu token pode ter expirado."
        },
        "200_tfaaddress": {
            title: "Verificação de endereço",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Obrigado por verificar seu endereço. Agora você pode escolher este como endereço de entrega ao fazer um pedido."
        },
        "404_tfaaddress": {
            title: "Verificação de endereço",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Token válido não encontrado. Seu token pode ter expirado."
        },
        "201_delivery": {
            title: "Obrigado",
            header: "Obrigado",
            message: "Obrigado pela sua resposta."
        },
        "201_shipment_received": {
            title: "Obrigado",
            header: "Obrigado",
            message: "Obrigado pela sua resposta."
        },
        "400": {
            title: "Pedido ruim. Um erro ocorreu.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Pedido ruim. Um erro ocorreu."
        }
    }
}