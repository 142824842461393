export default {
    home: "Lar",
    order: {
        submit: "Revisar pedido"
    },
    tunnel: {
        loginVerification: "Verificação de login",
        addressVerification: "Verificação de endereço"
    },
    catalog: "Catálogo",
    all: "Todos",
    return: {
        return: "Retornar"
    },
    errors: {
        404: "@:views.errors.404.title"
    }
}