export default {
    title: "Revisão do pedido",
    header: "@:views.order.submitted.title",

    noItems: "Nenhum produto selecionado",

    model: {
        addressId: {
            errors: {
                required: "Por favor preencha o endereço",
                noAddress: "Nenhum endereço está disponível. Entre em contato com o suporte."
            }
        },
        product: {
            placeholder: "Por favor preencha o valor"
        }
    },

    incompleteAddressMessage: "Por favor preencha os campos de endereço obrigatórios",
    completeAddress: "Completo",
    createNewAddress: "Criar novo endereço",

    button: {
        submit: {
            cta: "Enviar pedido",
            processing: "Enviando"
        },
        remove: "Remover do pedido"
    },

    errors: {
        itemNotExist: "Item «{name}» não existe no catálogo. Ele será removido.",
        requestError: "Ocorreu um problema ao enviar seu pedido. Por favor, tente enviar novamente."
    }
}