const countryAfterComponentsBeforeSelection = "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você. Se você não vir seu país na lista, o Gear não está disponível no momento em seu país.";
const countryAfterComponentsAllowedSelection = "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você."
const countryAfterComponentsLimitedSelection = "O Gear está atualmente disponível em seu país, mas está sendo inaugurado em etapas. A menos que você tenha recebido uma notificação, talvez você não consiga acessar o Gear neste momento em seu país.";

const disclaimer = "Ao clicar no próximo botão abaixo, reconheço que sou funcionário da Empresa e autorizo que a Empresa e seu parceiro RemoteRetail armazenem e processem as informações pessoais enviadas neste site para me fornecer o conteúdo solicitado. A RemoteRetail precisa das informações de contato que forneço para que possam entrar em contato comigo com informações relacionadas aos meus pedidos. Fornecedores terceirizados também podem receber minhas informações para me enviar produtos.</p><p>Posso cancelar a assinatura dessas comunicações a qualquer momento. Para obter mais informações sobre como posso cancelar a assinatura, as práticas de privacidade e os compromissos para proteger e respeitar minha privacidade, consulte a <a href='{privacyPolicyUrl}' target='_blank'>Política de Privacidade</a>.";

export default {
    title: "Bem vindo, {userName}!",
    description: {
        default: "Temos orgulho de apresentar a Gear: <i>a</i> fonte de acessórios de TI da empresa. O Gear permite que você solicite equipamentos enviados diretamente para sua porta, sem sistemas de aquisição de terceiros.",
        virtualworker: "Temos orgulho de apresentar o Gear: <i>a</i> fonte de acessórios de TI da empresa que você precisa para trabalhar com eficiência em casa. O Gear permite que você solicite equipamentos enviados diretamente para sua porta, sem sistemas de aquisição de terceiros."
    },
    text: {
        default: "Para começar, faremos uma série de perguntas.",
        virtualworker: "Para começar, faremos uma série de perguntas para nos ajudar a entender seu espaço de trabalho em casa e ajudar a moldar a direção futura deste programa."
    },
    afterComponents: {
        beforeSelection: `${countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${countryAfterComponentsBeforeSelection}<br/><br/>${disclaimer}`,
        autoLoginAllowedSelection: `${countryAfterComponentsAllowedSelection}<br/><br/>${disclaimer}`,
        autoLoginLimitedSelection: `${countryAfterComponentsLimitedSelection}<br/><br/>${disclaimer}`
    },
    buttons: {
        back: "Voltar",
        next: "Próximo",
        submit: "Enviar"
    },
    entityinfo: {
        title: "Informações da Entidade",
        description: "Para nos ajudar a encaminhar corretamente seu pedido, forneça-nos as seguintes informações:",
    },
    equipmentsurvey: {
        title: "Pesquisa de Equipamentos",
        description: "A próxima página perguntará sobre o tipo de equipamento que você mais precisa para ajudar a Empresa a entender melhor as prioridades.",
        info: "Observe que o equipamento mostrado serve apenas para fins de coleta de informações e não implica que a Empresa fornecerá este equipamento no futuro."
    },
    workplace: {
        title: "Conte-nos mais sobre seu home office?",
        description: "Para nos ajudar a entender melhor seu espaço de trabalho, selecione uma das opções abaixo",
    },
    priority: {
        title: "Prioridades de equipamento",
        description: "Selecione suas duas principais opções de equipamento que você mais precisa"
    },
    "additional-questions": {
        title: "Questões adicionais"
    },
    "equipment-assessment": {
        title: "Avaliação de Equipamentos",
        description: "O seguinte conjunto de perguntas <b>determinará quais equipamentos estarão disponíveis para você encomendar no Gear com base em suas respostas.</b>"
    },
    headset: {
        title: "Equipamento atual"
    },
    "office-worker": {
        title: "Escritório"
    },
    "keyboard-mouse": {
        title: "Teclado e mouse"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "Hub USB"
    },
    "equipment-list": {
        title: "Equipamento",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Pedido ruim. Um erro ocorreu.",
            allowed: "Com base em suas respostas, você estará qualificado para os seguintes itens:{items}",
            empty: "Com base nas suas respostas, no momento você não será elegível para nenhum item do Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Se você precisar fazer alguma correção, clique no botão Voltar para alterar suas respostas.</small>"
    },
    saved: "Salvou"
}