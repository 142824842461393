export default {
    user: {
        create: {
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Preencha o e-mail"
                },
                name: {
                    label: "Nome",
                    placeholder: "Preencha o nome"
                },
                country: {
                    label: "País",
                    placeholder: "Preencha o país"
                }
            },
            cta: {
                submit: {
                    text: "Enviar",
                    processing: "Enviando"
                },
                cancel: {
                    text: "Cancelar"
                }
            }
        }
    }
}