export default {
    view: {
        success: {
            header: "Pesquisa enviada",
            message: "Obrigado pelo seu feedback."
        },
        productChoice: {
            cta: "Salvar",
            processing: "Salvando"
        },
        addressForm: {
            cta: "Salvar",
            processing: "Salvando"
        }
    },
    delivery: {
        title: "Pesquisa de entrega",
        message: "Você recebeu todos os itens listados abaixo?",
        cta: {
            yes: "Sim",
            no: "Não",
            processing: "Salvando"
        }
    }
}