export default {
    400: {
        title: "Pedido ruim",
        header: "@:views.errors.400.title",
        html: "Pedido ruim. Por favor, tente novamente mais tarde."
    },
    401: {
        title: "Encontramos um erro no login",
        header: "@:views.errors.401.title",
        html: "Entre em contato com <a href='{email}' target='_blank'>o suporte</a> ."
    },
    404: {
        title: "Página não encontrada",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Requisitos de elegibilidade",
        header: "Parece que você chegou um pouco cedo…",

        contact: "<p class='text-left'>Obrigado pelo seu interesse no Gear. Parece que você acessou o site antes de ser ativado e convidado diretamente. Os equipamentos estão sendo implementados em etapas, país por país e por grupos de usuários, uma vez que os requisitos regulatórios sejam resolvidos e o inventário esteja em vigor. Quando estiver ativado, você receberá um e-mail contendo um convite pessoal com instruções.</p><p class='text-left'> Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Suporte Gear</a> para dúvidas ou problemas com seu pedido ou no site Gear.</p>",

        subject: "Equipamento - Elegibilidade",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            eligibility: {
                header: "@:views.errors.403.header",
                subject: "Equipamento - Elegibilidade",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "eligibility-location": {
                header: "O equipamento está indisponível no momento",
                subject: "O equipamento está indisponível no momento",
                html: "Lamentamos muito pela inconveniência. No momento, a colocação de pedidos de equipamentos foi suspensa em sua região. Comunicaremos quando e se estiver disponível.",
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Sem acesso",
                subject: "Sem acesso",
                html: "Você não tem permissão para abrir esta página.",
            }
        }
    },
    500: {
        title: "Erro inesperado",
        header: "@:views.errors.500.title",
        message: "Um erro ocorreu. Por favor, tente novamente mais tarde."
    },
    code: {
        title: "Erro inesperado",
        message: "@:views.errors.code.title",
        error: "Erro {code}."
    }
}