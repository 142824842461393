export default {
    title: "Pesquisa com funcionários",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Numa escala de 1 a 10, onde 1 é muito insatisfeito e 10 é muito satisfeito, como avaliaria este serviço?",
        },
        service_likes: {
            label: "Do que você gostou?",
            placeholder: ""
        },
        service_improvements: {
            label: "O que pode ser melhorado?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Em uma escala de 1 a 10, sendo 1 nada claro e 10 fácil de acompanhar, como você avaliaria as comunicações sobre o Gear?"
        },
        item: {
            setup_rating: {
                label: "Em uma escala de 1 a 10, onde 1 é muito difícil e 10 é muito fácil, qual foi a dificuldade de instalação e configuração do seu \"{name}\"?",
            },
            comments: {
                label: "Forneça comentários sobre {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Informamos que suas respostas à pesquisa serão compartilhadas com a Empresa e eles poderão entrar em contato com você em relação ao seu feedback.",
    button: {
        submit: {
            cta: "Enviar",
            processing: "Enviando"
        }
    }
}